<template>
  <div>
    <div class="p-grid crud-demo">
      <div class="p-col-12">
        <div class="card layout-toppanel">
          <p ><i  class="pi pi-fw pi-info-circle"></i> Edite el registro de carta fianza o pólizas, deben registrarse correctamente siguiendo las pautas de casillas obligatorias representadas por (*)</p>
        </div>
        <div class="card">
          <Toast />
          <Toolbar class="p-mb-4">
            <template v-slot:left>
              <Button
                label="Guardar"
                icon="pi pi-check"
                class="p-button-info"
                @click="guardar"
                :disabled="isBussy"
            /></template>
            <template v-slot:right>
              <Button
                label="Salir"
                icon="pi pi-arrow-left"
                class="p-button-danger"
                @click="open('list')"
              />
            </template>
          </Toolbar>
          <div class="p-grid p-fluid dashboard">
            <div class="p-col-12 p-md-6 p-lg-6">
              <h5>Número de Carta(*)</h5>
              <InputText
                id="carta"
                v-model.trim="product.numero"
                required="true"
                placeholder="Nro. de carta o póliza"
                autofocus
                :class="{ 'p-invalid': submitted && !product.numero }"
              />
              <small class="p-invalid" v-if="submitted && !product.numero"
                >Ingrese número de carta o póliza.</small
              >
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
              <h5>Número de trámite</h5>
              <InputText
                id="expediente"
                v-model.trim="product.codigoBarra"
                required="false"
                placeholder="Nro. trámite del sistema gstramite"
              />
            </div>

            <div class="p-col-12 p-md-6 p-lg-6">
              <h5>Fecha Emisión(*)</h5>
              <Calendar
                :showIcon="true"
                :showButtonBar="true"
                v-model="product.fechaEmision"
                dateFormat="dd/mm/yy"
                :class="{ 'p-invalid': submitted && !product.fechaEmision }"
              ></Calendar>
              <small class="p-invalid" v-if="submitted && !product.fechaEmision"
                >Ingrese fecha de Emisión de la carta.</small
              >
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
              <h5>Fecha Vencimiento(*)</h5>
              <Calendar
                :showIcon="true"
                :showButtonBar="true"
                v-model="product.fechaVencimiento"
                dateFormat="dd/mm/yy"
                :class="{ 'p-invalid': submitted && !product.fechaVencimiento }"
              ></Calendar>
              <small
                class="p-invalid"
                v-if="submitted && !product.fechaVencimiento"
                >Ingrese fecha de Vencimiento de la carta.</small
              >
            </div>

            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Obra/Contratista(*)</h5>
              <div class="p-inputgroup">
                <Button
                  label="Buscar"
                  icon="pi pi-search"
                  @click="dialogObra"
                />
                <InputText
                  @click="dialogObra"
                  :readonly="true"
                  v-model.trim="product.obra"
                  required="true"
                  placeholder="Seleccione obra/contratista"
                  autofocus
                  :class="{ 'p-invalid': submitted && !product.obra }"
                />
              </div>
              <small class="p-invalid" v-if="submitted && !product.obra"
                >Seleccione la obra y contratista.</small
              >
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Banco(*)</h5>
              <Dropdown
                v-model="product.banco"
                required="true"
                :options="banco"
                :filter="true"
                optionLabel="nombre"
                :disabled="isBussy"
                placeholder="Seleccione Entidad Finaciera (Banco)"
                :class="{ 'p-invalid': submitted && !product.banco }"
                filterPlaceholder="Busque el banco"
              />
              <small class="p-invalid" v-if="submitted && !product.banco"
                >Seleccione el banco o entidad financiera.</small
              >
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
              <h5>Monto (*)</h5>
              <InputNumber
                v-model="this.product.monto"
                mode="decimal"
                locale="en-US"
                :minFractionDigits="2"
                :class="{ 'p-invalid': submitted && !product.monto }"
              />
              <small class="p-invalid" v-if="submitted && !product.monto"
                >Ingrese monto en soles S/.</small
              >
            </div>
            <div class="p-col-12 p-md-6 p-lg-6">
              <h5>TipoFianza (*)</h5>

              <Dropdown
                v-model="product.tipoFianza"
                :options="tipoFianza"
                :disabled="isBussy"
                optionLabel="nombre"
                loadingIcon="pi pi-spinner pi-spin"
                placeholder="Seleccione tipo fianza"
                :filter="true"
                filterPlaceholder="Busque tipo fianza"
                :class="{ 'p-invalid': submitted && !product.tipoFianza }"
              />
              <small class="p-invalid" v-if="submitted && !product.tipoFianza"
                >Seleccione el tipo fianza</small
              >
            </div>
            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Carta Digital (PDF)</h5>
              <FileUpload
                mode="basic"
                :customUpload="true"
                @uploader="myUploader"
                :fileLimit="1"
                :auto="true"
              />
              <label>Archivo: {{ filetitulo }} {{ filetype }}</label>
              <small class="p-invalid" v-if="submitted && !filetitulo">
                Archivo es requerido.</small
              >
            </div>

            <div class="p-col-12 p-md-12 p-lg-12">
              <h5>Observación</h5>
              <Textarea
                v-model="product.observacion"
                required="true"
                rows="3"
                cols="20"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="obraDialog"
    :style="{ width: '850px' }"
    header="Busque seleccione con doble click la obra y contratista"
    :modal="true"
    class="p-fluid"
  >
    <DataTable
      ref="dt"
      :value="obras"
      v-model:selection="selectedObras"
      selectionMode="single"
      dataKey="id"
      sortField="nombre"
      :sortOrder="1"
      :paginator="true"
      :rows="5"
      v-model:filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5, 10, 25]"
      currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cartas"
      responsiveLayout="scroll"
      :globalFilterFields="['nombre', 'contratista.nombre']"
      @dblclick="clickSeleccionObra"
    >
      <template #header>
        <div
          class="
            table-header
            p-d-flex p-flex-column p-flex-md-row p-jc-md-between
          "
        >
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global'].value"
              placeholder="Buscar..."
            />
          </span>
        </div>
      </template>
      <Column selectionMode="single" headerStyle="width: 3rem"></Column>
      <Column field="nombre" header="Obra" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">obra</span>
          {{ formatCurrency(slotProps.data.nombre) }}
        </template>
      </Column>
      <Column field="contratista.nombre" header="Contratista" :sortable="true">
        <template #body="slotProps">
          <span class="p-column-title">contratista.nombre</span>
          {{ slotProps.data.contratista.nombre }}
        </template>
      </Column>
    </DataTable>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    this.action = "gsfianza/CARTA_UPDATE";
    return {
      isBussy: false,
      selectedObras: null,
      obraDialog: false,
      obras: null,
      urlImage: "",
      submitted: false,
      submitted1: false,
      deleteDialogoImagen: false,
      select: null,
      product: {},
      filetitulo: "",
      filetype: "",
      
      banco: null,
      tipoFianza: null,
      products: [],
      productDialog: false,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nombre: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
    };
  },
  methods: {
    
    guardar() {
      this.submitted = true;

      if (this.product.numero.trim() && this.product.fechaVencimiento && this.product.fechaEmision && this.product.banco && this.selectedObras && this.product.tipoFianza && this.product.monto) {

        let fechaV = new Date(this.product.fechaVencimiento);
        let fechaE = new Date(this.product.fechaEmision);
        let fechaVV =
          fechaV.getDate() +
          "/" +
          (fechaV.getMonth() + 1) +
          "/" +
          fechaV.getFullYear();

        let fechaEE =
          fechaE.getDate() +
          "/" +
          (fechaE.getMonth() + 1) +
          "/" +
          fechaE.getFullYear();
        
        let modelo = {
          id: this.product.id,
          numero: this.product.numero,
          fechaVencimiento: fechaVV,
          fechaEmision: fechaEE,
          monto: this.product.monto,
          observacion: this.product.observacion  === undefined ? '' : this.product.observacion ,
          banco: this.product.banco,
          obra: this.selectedObras,
          tipoFianza: this.product.tipoFianza,
          codigoBarra:  this.product.codigoBarra  === undefined ? '' : this.product.codigoBarra,
          docuRuta: this.product.docuRuta  === undefined ? '' : this.product.docuRuta, 
          docuTipo:  this.product.docuTipo  === undefined ? '' :  this.product.docuTipo,
          ejecutora: this.product.ejecutora,
        };
        
        this.$swal({
          width: 370,
          text: "Cargando...",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch(this.action, modelo)
              .then((res) => {
                this.$swal.close();
                this.open("list");
                this.$toast.add({
                  severity: "success",
                  summary: "Successful",
                  detail: "Carta guardada con id: " + res.id,
                  life: 3000,
                });
              })
              .catch((error) => {
                this.$swal.close();
                this.error(error);
              });
          },
        }).then((result) => {
          console.log(result);
        });
      }
    },
  
    myUploader(file) {
      this.$swal({
        width: 370,
        text: "Subiendo archivo esperer porfavor...",
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: () => {
          this.$swal.showLoading();
          this.$store
            .dispatch("gsfianza/FILE_UPLOAD", file)
            .then((res) => {
              this.$swal.close();
              this.filetitulo = res.titulo;
              this.filetype = "[" + file.files[0].type + "]";
              this.product.docuTipo = file.files[0].type;
              this.product.docuRuta = res.destino;
            })
            .catch((error) => {
              this.$swal.close();
              this.fileTitulo = "error... " + error;
            });
        },
      }).then((result) => {
        console.log(result);
      });
    },
    formatCurrency(value) {
      if (value.length >= 150) {
        return value.substring(0, 130) + "...";
      } else {
        return value;
      }
    },
    listarBanco(e) {
      this.isBussy = true;
      this.$store
        .dispatch("gsfianza/BANCO_LIST", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.banco = res;
          let banco = this.product.banco;
          let sbanco =null;
          res.forEach(function(element) {
            if(banco.id == element.id){
               sbanco = element;
            }
          });
          this.product.banco = sbanco;
          
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },
    listarTipoFianza(e) {
      this.isBussy = true;
      this.$store
        .dispatch("gsfianza/TIPO_FIANZA_LIST", e === undefined ? true : e)
        .then((res) => {
          this.isBussy = false;
          this.tipoFianza = res;
          let fianza = this.product.tipoFianza;
          let sfianza =null;
          res.forEach(function(element) {
            if(fianza.id == element.id){
               sfianza = element;
            }
          });
          this.product.fianza = sfianza;
         
        })
        .catch((error) => {
          this.isBussy = false;
          this.error(error);
        });
    },
    dialogObra() {
      this.obraDialog = true;
      if (!this.obras) {
        this.$swal({
          width: 370,
          text: "Cargando....",
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: () => {
            this.$swal.showLoading();
            this.$store
              .dispatch("gsfianza/OBRA_LIST", null)
              .then((res) => {
                this.$swal.close();
                this.isBussy = false;
                this.obras = res;
              })
              .catch((error) => {
                this.$swal.close();
                this.isBussy = false;
                this.error(error);
              });
          },
        }).then((result) => {
          console.log(result);
        });
      }
    },
    open(operacion) {
      let mutacion = {
        oper: operacion,
        carta: {},
      };
      
      this.$router.push({
        name: "fianza/cartas"
       
      });
      this.$store.commit("gsfianza/OPERACION_CARTA", mutacion);
    },
    error(evt) {
      if (evt.response.status == 401 || evt.response.status == 500) {
        this.$swal(
          evt.response.data.errorMessage,
          "Ud. no tiene permitido esta función",
          "warning"
        );
      }
    },

    clickSeleccionObra() {
      this.obraDialog = false;
      this.product.obra =
        this.selectedObras.contratista.nombre +
        " / " +
        this.selectedObras.nombre;
    },
  },

  mounted() {
    if (this.carta.estado == false) {
      this.$router.push({
        name: "fianza/cartas",
      });
    }

    this.product = this.carta;

    this.selectedObras = this.carta.obra;
    this.product.obra = this.carta.nombreContratista+"/"+this.carta.nombreObra
    this.filetitulo = this.carta.docuExtension;
    this.filetype = this.carta.docuTipo;

    this.listarBanco();
    this.listarTipoFianza();
    
  },

  computed: {
    ...mapGetters({ carta: "gsfianza/getModelCarta" }),
  },
};
</script>
<style>
</style>